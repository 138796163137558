/*
 * Copyright 2023 pyamsoft
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AppViewModel, createAppViewModel } from "./AppViewModel";
import { useObjectGraph } from "./AppObjectGraph";
import React from "react";

export const useAppViewModel = function (date: Date): AppViewModel {
  const graph = useObjectGraph();
  const { interactors } = graph;
  const { disneyland } = interactors;

  return React.useMemo(
    () => createAppViewModel(disneyland, date),

    [disneyland, date],
  );
};
